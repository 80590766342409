import Venue1 from "./2021Venue1.jpg"
import Venue2 from "./2021Venue2.png"

import Winner1 from "./2021Winner1.jpg"
import Winner2 from "./2021Winner2.jpg"
import Winner3 from "./2021Winner3.jpg"
import Winner4 from "./2021Winner4.jpg"

const images2021 = {
    Venue1,
    Venue2,
    Winner1,
    Winner2,
    Winner3,
    Winner4
};

export default images2021;