import coverImage from './Cover_Image.jpg';
import QRInvite from './QR_Invite.png';
import title from './Title.png';
import MilleniumLogo from './MilleniumThreadsLogo.webp';

const imagesHome = {
    coverImage,
    QRInvite,
    title,
		MilleniumLogo
}

export default imagesHome;