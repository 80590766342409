import Venue1 from "./2019Venue1.jpg"
import Venue2 from "./2019Venue2.jpg"
import Venue3 from "./2019Venue3.jpg"
import Venue4 from "./2019Venue4.jpg"
import Venue5 from "./2019Venue5.jpg"

import Winner1 from "./2019Winner1.jpg"
import Winner2 from "./2019Winner2.jpg"
import Winner3 from "./2019Winner3.jpg"
import Winner4 from "./2019Winner4.jpg"

const images2019 = {
    Venue1,
    Venue2,
    Venue3,
    Venue4,
    Venue5,
    Winner1,
    Winner2,
    Winner3,
    Winner4
};

export default images2019;